<template>
      <div class="align-middle flex justify-center items-center rounded">
        <div class="container">
          <table class="min-w-full">
            <tbody>
                <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border- border-gray-100">
                    <td class="px-4 text-left py-3 whitespace-nowrap text-sm text-gray-500">
                      <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-3 inline-block" loading="lazy"></div>
                    </td>
                </tr>
                <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border- border-gray-100">
                    <td class="px-4 text-left py-3 whitespace-nowrap text-sm text-gray-500">
                      <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-3 inline-block" loading="lazy"></div>
                    </td>
                </tr>
                <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border- border-gray-100">
                    <td class="px-4 text-left py-3 whitespace-nowrap text-sm text-gray-500">
                      <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-3 inline-block"  loading="lazy"></div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
</template>

<script>
export default {};
</script>

