<template>
  <div class="mb-12">
    <SubHeader :type="$t('projectProfile.bar')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarProject />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto">
            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("functions.title") }}</div>
                  <div class="grid grid-cols-1 gap-4">
                    <TableSkeleton class="min-w-full" v-if="showSkeleton" />
                    <table v-else class="min-w-full rounded">
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                            <div class="flex items-center">
                              <div class="h-10 w-10 flex-shrink-0">
                                <Icon icon="tabler:plug" class="text-gray-800 w-10 h-10" />
                              </div>
                              <div class="ml-4">
                                <span class="indicator inline-block" :class="integrationsSync == 1 ? 'bg-light-green-500' : 'bg-gray-300'" v-tooltip.top="integrationsSync ? $t('integrations.active') : $t('integrations.disable')" />
                              </div>
                              <div class="ml-6 font-medium">{{ $t("functions.integrationSync") }}</div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <label class="cursor-pointer" for="integrationsSync">
                              <div>
                                <input @click="toggleIntegrations" type="checkbox" class="toggle toggle-accent" id="integrationsSync" v-model="integrationsSync" />
                                <span class="toggle-mark"></span>
                              </div>
                            </label>
                          </td>
                        </tr>

                        <tr>
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                            <div class="flex items-center">
                              <div class="h-10 w-10 flex-shrink-0">
                                <Icon icon="tabler:adjustments-horizontal" class="text-gray-800 w-10 h-10" />
                              </div>
                              <div class="ml-4">
                                <span class="indicator inline-block" :class="segmentsSync == 1 ? 'bg-light-green-500' : 'bg-gray-300'" v-tooltip.top="segmentsSync ? $t('integrations.active') : $t('integrations.disable')" />
                              </div>
                              <div class="ml-6 font-medium">{{ $t("functions.segmentSync") }} {{ segmentsSync }}</div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <label class="cursor-pointer" for="segmentsSync">
                              <div>
                                <input @click="toggleSegments" type="checkbox" class="toggle toggle-accent" id="segmentsSync" v-model="segmentsSync" />
                                <span class="toggle-mark"></span>
                              </div>
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </Card>
            <Card v-if="$verifyPermission(permissions, ['v2.client.project.empty.collection'])" class="mt-2">
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("emptyCollection.title") }}</div>
                  <div class="grid grid-cols-1 gap-4">
                    <TableSkeleton class="min-w-full" v-if="showSkeleton" />
                    <Dropdown v-model="collection" :options="['cart', 'content']" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full" />
                    <Button @click="sendEmptyCollection" :label="$t('emptyCollection.button')" :loading="loadingEmptyCollection" iconPos="right" class="p-button-primary w-max" />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import axiosClient from "@/config/AxiosClient"
import TransitionFade from "@/components/TransitionFade"
import SidebarProject from "@/components/SidebarProject"
import TableSkeleton from "@/components/UI/TableSkeleton"
import Card from "primevue/card"
import Dropdown from "primevue/dropdown"
import FormClient from "@/config/FormClient"
import ApiRoute from "@/config/ApiRoute"
import Button from "primevue/button"
import { Icon } from "@iconify/vue"
import { mapGetters } from 'vuex'

const sprintf = require("sprintf-js").sprintf

export default {
  name: "ProjectFunctions",
  components: {
    // Project Components
    SubHeader,
    TransitionFade,
    SidebarProject,
    TableSkeleton,

    // Framework components
    Card,
    Icon,
    Dropdown,
    Button
  },
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
  },
  data: function () {
    return {
      projectId: this.$route.params.id ? this.$route.params.id : null,
      showSkeleton: true,
      integrationsSync: true,
      segmentsSync: true,
      collection: "cart",
      loadingEmptyCollection: false,
    }
  },
  mounted() {
    this.getToggle()
    this.segmentsSync
  },
  methods: {
    async getToggle() {
      const res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.projectId}/console`)
      this.showSkeleton = false
      this.integrationsSync = !!res.data.find(f => f.name == 'integration').pivot.is_active
      this.segmentsSync = !!res.data.find(f => f.name == 'segment_integration').pivot.is_active
    },
    async toggleIntegrations() {
      try {
        await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.projectId}/console?console=integration`)
        this.$toast.success(this.$t("utils.toast.toggleIntegrations"))
        this.getToggle()
      } catch (error) {
        this.$toast.error(this.$t("utils.toast.error"))
        console.log(error)
      }
    },
    async toggleSegments() {
      try {
        await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.projectId}/console?console=segment`)
        this.$toast.success(this.$t("utils.toast.toggleSegments"))
        this.getToggle()
      } catch (error) {
        this.$toast.error(this.$t("utils.toast.error"))
        console.log(error)
      }
    },
    async sendEmptyCollection(){
      this.loadingEmptyCollection = true;
      try{
        await new FormClient().delete(sprintf(ApiRoute.project.emptyCollection, { project_id: this.projectId, collection: this.collection }))
        this.collection = "cart"
        this.$toast.success(this.$t("emptyCollection.success"))
      }catch(error){
        this.$toast.error(this.$t("utils.toast.error"));
      }
      this.loadingEmptyCollection = false;
    }
  }
}
</script>
